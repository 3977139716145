<template>
  <!-- <div id="app">
    <div id="nav">
      <Header />
    </div> -->
  <router-view />
  <!-- </div> -->
</template>

<script>
// @ is an alias to /src
import Header from "./components/Header.vue";

export default {
  name: "App",
  components: {
    Header,
  },
};
</script>

<style lang="scss">
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&family=Sora:wght@100..800&display=swap");

#app {
  overflow-x: hidden;
  // font-family: Avenir, Helvetica, Arial, sans-serif;
  font-family: "Sora", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

#nav {
  padding: 25px;
  background: #171734;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}

.cursor-pointer {
  cursor: pointer;
}

// input[type=radio] {
//   accent-color: pink !important;
// }

.underline {
  &:hover {
    text-decoration: underline;
  }
}

.custom-radio {
  display: flex !important;
  justify-content: start !important;
  align-items: center !important;
  gap: 12px !important;
  margin-bottom: 8px;

  .custom-control-label {
    font-size: 14px;
  }

  input[type="radio"] {
    -webkit-appearance: none;
    width: 18px;
    height: 18px;
    border-radius: 50%;
    outline: none;
    border: 1px solid gray;
  }

  input[type="radio"]:before {
    content: "";
    display: block;
    width: 60%;
    height: 60%;
    margin: 20% auto;
    border-radius: 50%;
  }

  input[type="radio"]:checked:before {
    background: orange;
  }

  input[type="radio"]:checked {
    border-color: orange;
  }
}

.main-cont {
  padding: 30px !important;

  // @media (max-width: 400px) {
  //   padding: 11px !important;
  // }

  @media (max-width: 450px) {
    padding: 0 !important;
  }

  .input__form {
    text-align: start;

    .vraag {
      color: #333333;
    }

    &-label {
      color: #676e80;
    }

    &-back_label {
      color: #b3b3b3;
    }

    &-field {
      background-color: #fcfcfc;
      border: 1px solid #fea700;
      border-radius: 10px;
      padding: 10px;
      width: 90%;
      margin-top: 6px !important;

      &::placeholder {
        color: black;
        opacity: 0.4;
      }

      &:focus {
        border-color: #fea700 !important;
        box-shadow: 0 0 0 0.25rem #ffc10770;
      }
    }

    &-btn {
      padding: 10px 25px !important;
      border-radius: 100px;
      background-color: #fea700 !important;
      border: none;
      display: flex;
      justify-content: center;
      align-items: center;

      @media (max-width: 375px) {
        padding: 4px 8px;
        font-size: 14px;
      }
    }

    &-div {
      cursor: pointer;
      padding: 1rem 0;
      text-align: center;
      border: 1px solid #fea700;
      border-radius: 12px;
      background-color: white;
      margin: 0 0.5rem;

      p {
        color: #000b29;
      }

      svg {
        width: 75px;
        height: 75px;
      }

      &-selected {
        cursor: pointer;
        padding: 1rem 0;
        text-align: center;
        background-color: #fea700;
        border-radius: 12px;

        p {
          color: #000b29;
        }

        svg {
          width: 75px;
          height: 75px;
        }
      }
    }

    .title {
      color: #333333;
      font-size: 35px;
      font-weight: 700;
      line-height: 43px;
      letter-spacing: 0.126px;
      text-align: left;
    }

    @media (max-width: 768px) {
      &-label {
        font-size: 14px;
      }

      .title {
        font-size: 25px;
        line-height: 35px;
      }
    }
  }
}

.modal {
  .modal-header {
    justify-content: space-between !important;

    button {
      background: transparent;
      border: none !important;
      font-size: 20px;
    }
  }

  .content_box {
    h4 {
      font-size: 19px;
    }

    h5 {
      margin-left: 0.5rem;
    }

    .inner_box {
      margin-left: 0.5rem;

      .inner_box-heading {
        text-decoration: underline;
        font-size: 18px;
      }
    }
  }
}
</style>
